import { Script } from 'gatsby';
import React, { useState } from 'react';

const HubspotEmbedForm = ({ formId, onLoad, hideClass = false }) => {
	const [loaded, setLoaded] = useState(false);

	return (
		<div id="hubspotFormContainer" className={hideClass ? '' : "hubspot-form lg:px-20 px-5"}>
			<Script id="hubspotFormLibrary" src="//js.hsforms.net/forms/embed/v2.js" onLoad={() => { setLoaded(true); onLoad && onLoad(); }} />
			{loaded && (
				<Script
					dangerouslySetInnerHTML={{
						__html: `
							window.hbspt.forms.create({
								target: "#hubspotFormContainer",
								region: "na1",
								portalId: "23632167",
								formId: "${formId}"
							});
						`,
					}}
				/>
			)}
		</div>
	);
};

export default HubspotEmbedForm;
