// import '@fontsource/lexend/300.css';
// import '@fontsource/lexend/400.css';
// import '@fontsource/lexend/500.css';
// import '@fontsource/lexend/700.css';
// Supports weights 100-900
import '@fontsource-variable/dm-sans';
import '@fontsource-variable/outfit';
import './src/styles/main.css';

export const onRouteUpdate = ({ location }, pluginOptions) => {
  if (!Array.isArray(window._hsq)) {
    return null;
  }

  const trackPageView = () => {
    const path = location ? `${location.pathname}${location.search}${location.hash}` : undefined;
    window._hsq.push(['setPath', path]);
    window._hsq.push(['trackPageView']);
  };

  const refreshChatFlow = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.refresh();
    };
  };

  if (typeof window.requestAnimationFrame === 'function') {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView);
      window.requestAnimationFrame(refreshChatFlow);
    });
  } else {
    setTimeout(trackPageView, 32);
    setTimeout(refreshChatFlow, 32);
  }

  return null;
};
