import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';

import Button from '../components/Button';
import NewsletterForm from '../components/NewsletterForm';
import LinkedInIcon from '../components/icons/LinkedInIcon';
import MediumIcon from '../components/icons/MediumIcon';
import TwitterIcon from '../components/icons/TwitterIcon';
import Link from '../components/Link';
import SocialLink from '../components/SocialLink';
import twitterImage from "../images/home/twitter-image.svg";
import linkInImage from "../images/home/linkIn_image.svg";
import mediumImage from "../images/home/medium_image.svg";

import LogoImageSrc from '../images/shared/logo.svg';
import ArrowRightIcon from '../components/icons/ArrowRightIcon';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      platformNavs: navigationYaml(yamlId: {eq: "/platform"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      solutionNavs: navigationYaml(yamlId: {eq: "/solutions"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      industryNavs: navigationYaml(yamlId: {eq: "/industry"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      resourcesNavs: navigationYaml(yamlId: {eq: "/resources"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      companyNavs: navigationYaml(yamlId: {eq: "/company"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
    }
  `);

  const {
    platformNavs,
    solutionNavs,
    industryNavs,
    resourcesNavs,
    companyNavs
  } = data;

  return (
    <>
      <footer className="bg-gray-100 lg:py-20 py-10">
        <div className="container-lg">
          <div className="row">
            <div className="col-full lg:col-3">
              <Link
                to="/"
                title="footer logo  Safelishare"
                className="block uppercase"
              >
                <img src={LogoImageSrc} alt="Logo" className="w-60" />
              </Link>
              <p className="lg:mt-8 text-2xl w-2/4 typo-h1">
                Stay Connected
              </p>
              <p className="text-gray-500">
                Keep up to date with Safelishare's latest developments and
                industry news.
              </p>
              <div className="mt-8">
                <NewsletterForm formId="5a641716-3c76-4d74-aefd-eeb0cc39ec4f" />
              </div>
              {/* <div className="flex mt-4">
                <div className="mr-4">
                  <SocialLink url="https://twitter.com/safelishare">
                    <TwitterIcon />
                  </SocialLink>
                </div>
                <div className="mr-4">
                  <SocialLink url="https://linkedin.com/company/safelishare">
                    <LinkedInIcon />
                  </SocialLink>
                </div>
                <div className="mr-4">
                  <SocialLink url="https://medium.com/@safelishare">
                    <MediumIcon />
                  </SocialLink>
                </div>
              </div> */}
              <div>
                <Button
                  to="https://linkedin.com/company/safelishare"
                  variant="primary"
                  size="md"
                  className="my-5 mr-5 gap-3 items-center bg-black text-[#fff] !rounded-sm border-0 uppercase"
                >
                  Follow Us on LinkedIn
                  <ArrowRightIcon className="h-5 w-5" />
                </Button>
                <Button
                  to="/contact/"
                  variant="primary"
                  size="md"
                  className="gap-3 items-center bg-black text-[#fff] !rounded-sm border-0 uppercase"
                >
                  Contact Us
                  <ArrowRightIcon className="h-5 w-5" />
                </Button>
              </div>
            </div>
            <div className="col-full lg:col-9">
              <div className="flex gap-10 justify-end">
                <Link
                  to="https://twitter.com/safelishare"
                  className="no-underline inline-flex items-center group"
                >
                  <img src={twitterImage} />
                </Link>
                <Link
                  to="https://linkedin.com/company/safelishare"
                  className="no-underline inline-flex items-center group"
                >
                  <img src={linkInImage} />
                </Link>
                <Link
                  to="https://medium.com/@safelishare"
                  className="no-underline inline-flex items-center group"
                >
                  <img src={mediumImage} />
                </Link>
              </div>
              <div className="row">
                <div className="col-full sm:col-6 md:col-3 lg:mt-12 mt-8">
                  <h2 className="typo-h4">{companyNavs.title}</h2>
                  <ul className="text-gray-500 md:mt-8 mt-3">
                    {companyNavs.items.map((nav) => (
                      <li className="md:mt-2" key={nav.title}>
                        <Link
                          to={nav.url}
                          title={nav.title}
                          className="typo-body no-underline"
                        >
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-full sm:col-6 md:col-3 lg:mt-12 mt-8">
                  <h2 className="typo-h4">{platformNavs.title}</h2>
                  <ul className="text-gray-500 md:mt-8 mt-3">
                    {platformNavs.items.map((nav) => (
                      <li className="md:mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-full sm:col-6 md:col-3 lg:mt-12 mt-8">
                  <h2 className="typo-h4">{solutionNavs.title}</h2>
                  <div>
                    <div className="uppercase text-black pt-8 font-medium">
                      By Use Case
                    </div>
                    <ul className="text-gray-500">
                      {solutionNavs.items.map((nav) => (
                        <li className="md:mt-2" key={nav.title}>
                          <Link to={nav.url} className="typo-body no-underline">
                            {nav.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                  <div className="uppercase text-black pt-8 font-medium">
                    By Industry
                  </div>
                  <ul className="text-gray-500">
                    {industryNavs.items.map((nav) => (
                      <li className="md:mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  </div>
                </div>
                <div className="col-full sm:col-6 md:col-3 lg:mt-12 mt-8">
                  <h2 className="typo-h4">
                    {resourcesNavs.title}
                  </h2>
                  <ul className="text-gray-500 md:mt-8 mt-3">
                    {resourcesNavs.items.map((nav) => (
                      <li className="md:mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
               {/*  <div className="col-full sm:col-6 md:col-3 lg:mt-12 mt-8">
                  <h2 className="typo-h4">{industryNavs.title}</h2>
                  <ul className="text-gray-500 md:mt-8 mt-3">
                    {industryNavs.items.map((nav) => (
                      <li className="md:mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center md:justify-between mt-12">
            <div className="w-full md:w-auto text-center">
              <small className="typo-small text-gray-500">
                2023 SafeLiShare, Inc., All rights reserved.
              </small>
            </div>
            <div className="flex flex-col md:flex-row text-center flex-wrap items-center mt-4 md:mt-0">
              <div className="flex items-center">
                <Link
                  to="/privacy-policy/"
                  title="privacy-policy | Safelishare"
                  className="typo-small text-gray-500 no-underline"
                >
                  Privacy Policy
                </Link>
                <span className="inline-block h-3 w-px bg-gray-500 mx-2" />
                <Link
                  to="/cookie-policy/"
                  title="cookie-policy | Safelishare"
                  className="typo-small text-gray-500 no-underline mr-4"
                >
                  Cookie Policy
                </Link>
              </div>
              {/* <div className="flex mt-4 md:mt-0">
                <div className="mr-4">
                  <Button
                    to="/demo/"
                    title="Request a Demo | Safelishare"
                    variant="primary"
                    size="md"
                    className="bg-black border-r-none border-0 font-normal"
                  >
                    Request a Demo
                  </Button>
                </div>
                <div>
                  <Button
                    to="/contact/"
                    title="Contact Us | Safelishare"
                    variant="secondary"
                    size="md"
                    className="bg-white border-r-none text-black border border-solid border-black"
                  >
                    Contact Us
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
