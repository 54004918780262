import classnames from 'classnames';
import React from 'react';

const MenuButton = ({ isActive, onClick }) => {
  return (
    <button type="button" className="h-6 w-8 flex flex-col justify-between items-end relative" onClick={onClick}>
      <span className={classnames('bg-black w-full h-0.5 transition-opacity duration-200', {
          'opacity-0': isActive,
        })}
      />
      <span className="h-0.5 w-full block relative">
        <span className={classnames('block bg-black w-full h-0.5 transition-transform origin-center absolute', {
            'rotate-45': isActive,
          })}
        />
        <span className={classnames('block bg-black w-full h-0.5 transition-transform origin-center absolute', {
            '-rotate-45': isActive,
          })}
        />
      </span>
      <span className={classnames('bg-black w-full h-0.5 transition-opacity duration-200', {
          'opacity-0': isActive,
        })}
      />
    </button>
  );
};

export default MenuButton;
