import classnames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { useStaticQuery, graphql, navigate } from "gatsby";
import React, { useRef } from "react";

import Button from "../components/Button";
import Link from "../components/Link";
import NavLink from "../components/NavLink";

import ChevronDownIcon from "../components/icons/ChevronDownIcon";
import ChevronLeftIcon from "../components/icons/ChevronLeftIcon";
import ChevronRightIcon from "../components/icons/ChevronRightIcon";
import MenuButton from "../components/MenuButton";

import ArrowRightIcon from "../components/icons/ArrowRightIcon";

import { useLayoutActions, useLayoutState } from "./Context";

import useOutsideClick from "../hooks/useOutsideClick";

import LogoImageSrc from "../images/shared/logo.svg";
import productMenu from "../images/home/productMenu.png";
import solutionMenu from "../images/home/solutionMenu.png";
import resourceMenu from "../images/home/resourceMenu.png";
import companyMenu from "../images/home/companyMenu.png";

const sidebarVariants = {
  menuOpen: () => ({
    x: "0%",
    transition: { type: "spring", stiffness: 120, damping: 20 },
  }),
  menuClosed: () => ({
    x: "100%",
    transition: { type: "spring", stiffness: 120, damping: 15 },
  }),
};

const navVariants = {
  enter: { opacity: 0 },
  idle: { opacity: 1, transition: { staggerChildren: 0.07 } },
  exit: { opacity: 0 },
};

const itemVariants = {
  enter: { opacity: 0, x: "-5%" },
  idle: { opacity: 1, x: 0 },
  exit: { opacity: 0 },
};

const transition = {
  type: "tween",
};

const Header = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainNavs: navigationYaml(yamlId: { eq: "/" }) {
        id: yamlId
        title
        items {
          title
          group
        }
      }
      platformNavs: navigationYaml(yamlId: { eq: "/platform" }) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      productformNavs: navigationYaml(yamlId: { eq: "/productform" }) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      solutionNavs: navigationYaml(yamlId: { eq: "/solutions" }) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      industryNavs: navigationYaml(yamlId: { eq: "/industry" }) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      resourcesNavs: navigationYaml(yamlId: { eq: "/resources" }) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      companyNavs: navigationYaml(yamlId: { eq: "/company" }) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
    }
  `);

  const {
    mainNavs,
    platformNavs,
    productformNavs,
    solutionNavs,
    industryNavs,
    resourcesNavs,
    companyNavs,
  } = data;

  const headerRef = useRef();

  const { activeGroup, menuOpen, isMediumScreen } = useLayoutState();
  const { setActiveGroup, closeMenu, toggleMenu } = useLayoutActions();

  const isActiveGroup = (group) => {
    return activeGroup === group;
  };

  const isActiveNested = (group) => {
    return activeGroup ? activeGroup.includes(group) : false;
  };

  const handleNav = (nav) => {
    if (nav.url) {
      navigate(nav.url);
      closeMenu();
    }

    if (isActiveGroup(nav.group)) {
      closeMenu();
    } else {
      setActiveGroup(nav.group);
    }
  };

  useOutsideClick(headerRef, closeMenu, true);

  return (
    <>
      <header
        className={classnames("relative z-50 border-b", {
          "bg-[#FFFFFF] from-white to-gray-200": !activeGroup,
          "bg-white": activeGroup,
        })}
        ref={headerRef}
      >
        <div className="container-lg">
          <div className="flex items-center justify-between h-24">
            <div className="flex items-center">
              <div className="min-w-[220px]">
                <Link
                  to="/"
                  onClick={() => {
                    closeMenu();
                  }}
                  className="block uppercase"
                >
                  <img src={LogoImageSrc} alt="Logo" className="h-10" />
                </Link>
              </div>

              {!isMediumScreen && (
                <div className="xl:ml-12 ml-10 hidden lg:flex items-center justify-between">
                  <div className="xl:mr-9 mr-5">
                    <Button
                      variant="reset"
                      size="reset"
                      onClick={() => {
                        navigate("/why-safelishare");
                        closeMenu();
                      }}
                    >
                      <span>Why SafeLiShare</span>
                    </Button>
                  </div>
                  {mainNavs.items.map((nav) => (
                    <div className="xl:mr-9 mr-5" key={nav.title}>
                      <Button
                        variant="reset"
                        size="reset"
                        className={classnames(
                          "py-9 border-b-4 rounded-none border-b-transparent mt-1",
                          {
                            // 'hover:text-green-400 ': !isActiveGroup(nav.group),
                            "!border-b-black": isActiveGroup(nav.group),
                          }
                        )}
                        onClick={() => {
                          handleNav(nav);
                        }}
                      >
                        <span key={`main-${nav.title}`}>{nav.title}</span>
                        <ChevronDownIcon />
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!isMediumScreen && (
              <div className="flex">
                <div className="mr-4">
                  <Button
                    to="/demo/"
                    variant="primary"
                    size="md"
                    className="gap-3 items-center bg-black text-[#fff] !rounded-sm border-0 uppercase"
                  >
                    Request a Demo
                    <ArrowRightIcon className="h-4 w-4" />
                  </Button>
                </div>
                {/* <div>
                  <Button to="/contact/" variant="secondary" size="md" className='bg-white border-r-none text-black border border-solid border-black'>
                    Try Us
                  </Button>
               </div> */}
              </div>
            )}
            {isMediumScreen && (
              <MenuButton
                onClick={() => {
                  toggleMenu();
                }}
                isActive={menuOpen}
              />
            )}
          </div>
        </div>
        {!isMediumScreen && (
          <AnimatePresence>
            {activeGroup && (
              <motion.div
                className="bg-white mt-24 absolute top-0 w-full shadow-xl"
                initial="enter"
                animate="idle"
                exit="exit"
                variants={navVariants}
                transition={transition}
              >
                <div className="border-b" />
                <div className="container-lg">
                  <div className="py-10">
                    {isActiveGroup(platformNavs.id) && (
                      <motion.div
                        className="grid grid-cols-3"
                        variants={navVariants}
                        exit="exit"
                        animate="idle"
                        initial="enter"
                      >
                        <div className="col-span-2 gird grid-cols-2 gap-5">
                          <motion.h2
                            variants={itemVariants}
                            className="uppercase text-[#525252] border-b border-b-[#E9E9E9] pb-2"
                          >
                            Overview
                          </motion.h2>
                          <div className="grid grid-cols-2 gap-5 pt-6">
                            <ul>
                              <NavLink to="/universal-ai-security">
                                <div>
                                  <div className="text-lg">
                                    Universal AI Security Platform Overview
                                  </div>
                                  <div>Confidentiality control at AI scale</div>
                                </div>
                              </NavLink>
                            </ul>
                            <ul>
                              <NavLink to="/universal-ai-security">
                                <div className="font-medium">Orchestrate</div>
                              </NavLink>
                              <div className="text-[#7d7d7d]">
                                Safely coordinates the access and flow of
                                sensitive data across LLM RAG workflows
                              </div>

                              <div className="pt-8">
                                <NavLink to="/universal-ai-security">
                                  <div className="font-medium">Secure</div>
                                </NavLink>
                                <div className="text-[#7d7d7d]">
                                  Protects data integrity and confidentiality
                                  throughout the embedding, indexing, and
                                  inferencing processes
                                </div>
                              </div>

                              <div className="pt-8">
                                <NavLink to="/universal-ai-security">
                                  <div className="font-medium">Monitor</div>
                                </NavLink>

                                <div className="text-[#7d7d7d]">
                                  Provides real-time visibility and control over
                                  data and model interactions to ensure
                                  compliance and prevent vulnerabilities
                                </div>
                              </div>
                            </ul>
                          </div>
                          <div className="grid grid-cols-2 gap-5 pt-10">
                            <div>
                              <motion.h2
                                variants={itemVariants}
                                className="uppercase text-[#525252] border-b pb-2"
                              >
                                Key Features
                              </motion.h2>
                              <ul className="mt-8">
                                {platformNavs.items.map((nav) => (
                                  <NavLink to={nav.url} key={nav.title}>
                                    {nav.title}
                                  </NavLink>
                                ))}
                              </ul>
                            </div>
                            {/*<div>
                              <motion.h2
                                variants={itemVariants}
                                className="uppercase text-[#525252] border-b pb-2"
                              >
                                Getting Started
                              </motion.h2>
                              <ul className="mt-8">
                                {productformNavs.items.map((nav) => (
                                  <NavLink to={nav.url} key={nav.title}>
                                    {nav.title}
                                  </NavLink>
                                ))}
                              </ul>
                            </div>*/}
                          </div>
                        </div>
                        <div className="col-span-1 pt-8 px-5">
                          <img src={productMenu} alt="product" />
                          <div className="bg-black text-white py-16 px-14 rounded-b-[20px]">
                            <div className="w-8/12 pb-10">
                              <p className="font-normal">eBook</p>
                              <p className="text-[24px] pt-5">
                                Secure Enclave as a Service
                              </p>
                            </div>
                            <Button
                              to={`/download-page?url=b7bfd201-360b-486c-bfb3-80616d92c3fd`}
                              variant="primary"
                              size="md"
                              className="gap-3 items-center bg-black text-[#fff] !rounded-sm border border-white uppercase"
                            >
                              Download ebook
                              <ArrowRightIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                    {isActiveGroup(solutionNavs.id) && (
                      <>
                        <motion.div
                          className="grid grid-cols-3 gap-5"
                          variants={navVariants}
                          exit="exit"
                          animate="idle"
                          initial="enter"
                        >
                          <div className="col-span-2">
                            <div className="grid grid-cols-2 gap-5">
                              <div>
                                <motion.h2
                                  variants={itemVariants}
                                  className="uppercase text-[#525252] border-b pb-2"
                                >
                                  By Use Case
                                </motion.h2>
                                <ul className="mt-8">
                                  {solutionNavs.items.map((nav) => (
                                    <NavLink to={nav.url} key={nav.title}>
                                      {nav.title}
                                    </NavLink>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <motion.h2
                                  variants={itemVariants}
                                  className="uppercase text-[#525252] border-b pb-2"
                                >
                                  By Industry
                                </motion.h2>
                                <ul className="mt-8">
                                  {industryNavs.items.map((nav) => (
                                    <NavLink to={nav.url} key={nav.title}>
                                      {nav.title}
                                    </NavLink>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            {/* <Button
                              variant="reset"
                              size="reset"
                              to="/"
                              className="font-bold uppercase pt-12"
                            >
                              View All Solutions
                              <ArrowRightIcon className="h-5 w-5 ml-2" />
                            </Button> */}
                          </div>
                          <div className="pt-8 px-5">
                            <img src={solutionMenu} alt="product" />
                            <div className="bg-black text-white py-16 px-14 rounded-b-[20px]">
                              <div className="pb-10">
                                <p className="font-normal">Gartner Research</p>
                                <p className="text-[24px] pt-5">
                                  Secure Generative Communication for LLMs and
                                  AI Agents
                                </p>
                              </div>
                              <Button
                                to={`/download-page?url=a9d53bf9-14d1-45cd-9f39-715b8c909887`}
                                variant="primary"
                                size="md"
                                className="gap-3 items-center bg-black text-[#fff] !rounded-sm border border-white uppercase"
                              >
                                Download THE REPORT
                                <ArrowRightIcon className="h-5 w-5" />
                              </Button>
                            </div>
                          </div>
                        </motion.div>
                      </>
                    )}
                    {isActiveGroup(resourcesNavs.id) && (
                      <motion.div
                        className="grid grid-cols-3"
                        variants={navVariants}
                        exit="exit"
                        animate="idle"
                        initial="enter"
                      >
                        <div>
                          <motion.h2
                            variants={itemVariants}
                            className="uppercase text-[#525252] border-b pb-2"
                          >
                            {resourcesNavs.title}
                          </motion.h2>
                          <ul className="mt-8">
                            {resourcesNavs.items.map((nav) => (
                              <NavLink to={nav.url} key={nav.title}>
                                {nav.title}
                              </NavLink>
                            ))}
                          </ul>
                        </div>
                        <div className="col-sapn-1 col-start-3 pt-8 px-5">
                          <img src={resourceMenu} alt="product" />
                          <div className="bg-black text-white py-16 px-14 rounded-b-[20px]">
                            <div className="pb-10">
                              <p className="font-normal">Checklist</p>
                              <p className="text-[24px] pt-5">
                                Building GenAI Applications Securely
                              </p>
                            </div>
                            <Button
                              to={`/download-page?url=c9517232-ee48-4cd4-81f4-7c55294f0797`}
                              variant="primary"
                              size="md"
                              className="gap-3 items-center bg-black text-[#fff] !rounded-sm border border-white uppercase"
                            >
                              Download THE REPORT
                              <ArrowRightIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                    {isActiveGroup(companyNavs.id) && (
                      <motion.div
                        className="grid grid-cols-12"
                        variants={navVariants}
                        exit="exit"
                        animate="idle"
                        initial="enter"
                      >
                        <div className="col-span-5">
                          <motion.h2
                            variants={itemVariants}
                            className="uppercase text-[#525252] border-b pb-2"
                          >
                            {companyNavs.title}
                          </motion.h2>
                          <ul className="mt-8">
                            {companyNavs.items.map((nav) => (
                              <NavLink to={nav.url} key={nav.title}>
                                {nav.title}
                              </NavLink>
                            ))}
                          </ul>
                        </div>
                        <div className="col-span-7 col-start-9 pt-8 px-5">
                          <img src={companyMenu} alt="product" />
                          <div className="bg-black text-white py-16 px-14 rounded-b-[20px]">
                            <div className="pb-10">
                              <p className="font-normal">eBook</p>
                              <p className="text-[24px] pt-5">
                                Software Defined Secure Enclaves
                              </p>
                            </div>
                            <Button
                              to={`/download-page?url=ab0d05b2-4c40-4431-8b9c-c79d0f9e0edc`}
                              variant="primary"
                              size="md"
                              className="gap-3 items-center bg-black text-[#fff] !rounded-sm border border-white uppercase"
                            >
                              Download Ebook
                              <ArrowRightIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        )}
        {isMediumScreen && (
          <motion.div
            className="bg-white fixed w-full sm:w-96 top-0 right-0 h-screen z-50"
            variants={sidebarVariants}
            animate={menuOpen ? "menuOpen" : "menuClosed"}
            initial={false}
          >
            <div className="flex flex-col h-full">
              <div className="px-4 flex items-center justify-between h-24">
                <div>
                  <Link
                    to="/"
                    onClick={() => {
                      closeMenu();
                    }}
                    className="block uppercase"
                  >
                    <img src={LogoImageSrc} alt="Logo" className="h-8" />
                  </Link>
                </div>
                <MenuButton
                  onClick={() => {
                    closeMenu();
                  }}
                  isActive={menuOpen}
                />
              </div>
              <div className="flex-1 overflow-y-auto px-4 md:px-8">
                {isActiveGroup(null) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    {mainNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        {nav.url && (
                          <Link
                            to={nav.url}
                            className="typo-blockquote no-underline"
                          >
                            {nav.title}
                          </Link>
                        )}
                        {nav.group && (
                          <span
                            onClick={() => {
                              handleNav(nav);
                            }}
                            className="typo-blockquote flex items-center justify-between cursor-pointer"
                            key={`main-${nav.title}`}
                          >
                            {nav.title}
                            <ChevronRightIcon />
                          </span>
                        )}
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(platformNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    <motion.li variants={itemVariants} className="mt-4">
                      <span
                        onClick={() => {
                          handleNav({ group: null });
                        }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    {platformNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            handleNav(nav);
                          }}
                          className="typo-blockquote no-underline"
                        >
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(solutionNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav mb-8"
                  >
                    <motion.li variants={itemVariants} className="mt-4">
                      <span
                        onClick={() => {
                          handleNav({ group: null });
                        }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    <motion.li variant={itemVariants} className="mt-4">
                      <span className="typo-body">{solutionNavs.title}</span>
                    </motion.li>
                    {solutionNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            handleNav(nav);
                          }}
                          className="typo-blockquote no-underline"
                        >
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                    <motion.li variant={itemVariants} className="mt-8">
                      <span className="typo-body">{industryNavs.title}</span>
                    </motion.li>
                    {industryNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            handleNav(nav);
                          }}
                          className="typo-blockquote no-underline"
                        >
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(resourcesNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    <motion.li variants={itemVariants} className="mt-4">
                      <span
                        onClick={() => {
                          handleNav({ group: null });
                        }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    {resourcesNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            handleNav(nav);
                          }}
                          className="typo-blockquote no-underline"
                        >
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(companyNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    <motion.li variants={itemVariants} className="mt-4">
                      <span
                        onClick={() => {
                          handleNav({ group: null });
                        }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    {companyNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            handleNav(nav);
                          }}
                          className="typo-blockquote no-underline"
                        >
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </header>
    </>
  );
};

export default Header;
