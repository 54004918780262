import classnames from 'classnames';
import React from 'react';

import Link from '../Link';

import PropTypes from '../../utils/PropTypes';

const SocialLink = ({ children, className, url }) => {
  return (
    <Link to={url} title= "Social Media | SafeLiShare" className={classnames(
      'block bg-black text-white p-2.5 rounded-full no-underline hover:shadow-md',
      className
    )}
    >
      {children}
    </Link>
  )
};

SocialLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
};

SocialLink.defaultProps = {
  className: '',
};

export default SocialLink;
