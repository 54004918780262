import classnames from 'classnames';
import React from 'react';

import Link from '../Link';

import PropTypes from '../../utils/PropTypes';
import warning from '../../utils/warning';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    theme: PropTypes.oneOf(['default', 'alt']),
    variant: PropTypes.oneOf(['default', 'reset', 'primary', 'secondary', 'accent']),
    size: PropTypes.oneOf(['default', 'reset', 'md', 'sm']),
    submit: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
    theme: 'default',
    variant: 'default',
    size: 'default',
    submit: false,
    onClick: null,
    to: null,
};

const Button = ({
    children,
    className,
    onClick,
    submit,
    theme,
    variant,
    size,
    to,
    ...rest
}) => {
    const buttonClassName = classnames(
        className,
        'btn no-underline',
        {
            'bg-transparent': variant === 'default' && variant === 'reset',
            'btn-primary': theme === 'default' && variant === 'primary',
            'btn-secondary': theme === 'default' && variant === 'secondary',
            'btn-accent': theme === 'default' && variant === 'accent',
            'btn-primary-alt': theme === 'alt' && variant === 'primary',
            'btn-secondary-alt': theme === 'alt' && variant === 'secondary',
            'btn-accent-alt': theme === 'alt' && variant === 'accent',
            'btn-reset': size === 'reset',
            'btn-md': size === 'md',
            'btn-sm': size === 'sm',
        },
    );

    warning(
        !(submit && onClick),
        'You provide onClick to a submit button. Please remove onClick handler or submit attribute',
    );

    if (submit) {
        return (
            <button type="submit" className={buttonClassName}>
                {children}
            </button>
        );
    }

    if (to) {
        return (
            <Link to={to} onClick={onClick} className={buttonClassName} {...rest}>
                {children}
            </Link>
        );
    }

    return (
        <button type="button" onClick={onClick} className={buttonClassName}>
            {children}
        </button>
    );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default React.memo(Button);
