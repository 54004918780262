import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const LinkedInIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        d="M6.77755 4.90084C6.77729 5.40473 6.57806 5.88787 6.22367 6.244C5.86928 6.60012 5.38876 6.80004 4.88783 6.79979C4.38689 6.79954 3.90658 6.59913 3.55254 6.24265C3.1985 5.88617 2.99975 5.40283 3 4.89894C3.00025 4.39506 3.19949 3.91192 3.55388 3.55579C3.90827 3.19967 4.38878 2.99975 4.88972 3C5.39065 3.00025 5.87097 3.20066 6.225 3.55714C6.57904 3.91362 6.7778 4.39696 6.77755 4.90084ZM6.83421 8.20666H3.05666V20.1H6.83421V8.20666ZM12.8027 8.20666H9.04407V20.1H12.765V13.8588C12.765 10.382 17.2697 10.0591 17.2697 13.8588V20.1H21V12.5669C21 6.70574 14.3326 6.92423 12.765 9.80257L12.8027 8.20666Z"
        fill="currentColor"
      />
    </svg>  
  );
};

LinkedInIcon.propTypes = propTypes;
LinkedInIcon.defaultProps = defaultProps;

export default React.memo(LinkedInIcon);
