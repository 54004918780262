import { motion } from 'framer-motion';
import React from 'react';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import Link from '../Link';
import { useLayoutActions } from '../../layout/Context';
import PropTypes from '../../utils/PropTypes';

const itemVariants = {
  enter: { opacity: 0 },
  idle: { opacity: 1 },
  exit: { opacity: 0 },
};

const NavLink = ({ children, to }) => {
  const { closeMenu } = useLayoutActions();

  return (
    <motion.li
      className="mt-2.5"
      variants={itemVariants}
    >
      <Link to={to} onClick={closeMenu} title={` Navigation menu | SafeLiShare`} className="inline-flex items-center no-underline group">
        {children}
        <span className="relative ml-2 opacity-0 group-hover:opacity-100 group-hover:ml-4 duration-300 ">
          <ArrowRightIcon className="w-5 h-5" />
        </span>
      </Link>
    </motion.li>
  );
};

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavLink;
