import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const ChevronDownIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        d="M11.9998 14.9998L7.75684 10.7568L9.17184 9.34277L11.9998 12.1718L14.8278 9.34277L16.2428 10.7568L11.9998 14.9998Z"
        fill="currentColor"
      />
    </svg>
  );
};

ChevronDownIcon.propTypes = propTypes;
ChevronDownIcon.defaultProps = defaultProps;

export default React.memo(ChevronDownIcon);
