import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const ChevronLeftIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        d="M11.828 11.9998L14.657 14.8278L13.243 16.2428L9 11.9998L13.243 7.75684L14.657 9.17184L11.828 11.9998Z"
        fill="currentColor"
      />
    </svg>
  );
};

ChevronLeftIcon.propTypes = propTypes;
ChevronLeftIcon.defaultProps = defaultProps;

export default React.memo(ChevronLeftIcon);
